// Log Rocket
import LogRocket from 'logrocket'

const app_id = process.env.LOGROCKET_APP_ID
LogRocket.init(app_id)

// This is an example script - don't forget to change it!
LogRocket.identify(gon.current_user_id, {
  email: gon.current_user_email,
  // Add your own custom user variables here, ie:
  subscriptionType: 'basic'
})
